@font-face {
  font-family: psb;
  src: url("https://nkpl.namishkumar.in/Product_Sans_Bold.ttf");
}
@font-face {
  font-family: productSans;
  src: url("https://nkpl.namishkumar.in/psr.ttf");
}
body {
  margin: 0;
  font-family:productSans;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.inf {
   font-family: psb;
   text-align: center;
}
h1 {
    font-size:calc(32px + 2vmin);
}
p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 22px;
}
.hero {
   text-align: center;
   background-color: #5E17EB;
   padding: 25px;
   margin-left: 8px;
   margin-right: 8px;
   color: white;
   border-radius: 2.5%;
}
.hero button {
    border: none;
    outline: none;
    background-color: #000000;
    margin:10px;
    padding: 20px;
    width: 10em;
    color: white;
    font-size: 20px;
    border-radius: 360px;
    transition: 0.1s;
}
.hero button:hover {
  border: #FFFFFF;
  border-width: 2px;
  border-style: solid;
  outline: #FFFFFF;
  background-color: #000000;
  margin:10px;
  padding: 20px;
  width: 10em;
  color: white;
  font-size: 20px;
  border-radius: 360px;
  transition: 0.1s;
}
.hero button:active {
  border: #FFFFFF;
  border-width: 2px;
  border-style: solid;
  outline: #FFFFFF;
  background-color: #FFFFFF;
  margin:10px;
  padding: 20px;
  width: 10em;
  color: black;
  font-size: 20px;
  border-radius: 360px;
  transition: 0.45s;
}
